import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

import syslic from '@/syslic'

export default [
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('@/views/order/delivery/DeliveryList.vue'),
    meta: {
      pageTitle: 'Entregas',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          text: 'Todas as Entregas',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/order/:id/delivery/view/:idDelivery',
    name: 'delivery-detail',
    component: () => import('@/views/order/delivery/DeliveryEdit.vue'),
    meta: {
      pageTitle: 'Entregas',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Entregas',
          toName: 'delivery',
        },
        {
          prefix: '#',
          routerParam: 'idDelivery',
          toName: 'delivery-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
      beforeEnter: (to, from, next) => {
        const canEdit = to.meta.permissions.can_edit

        if (canEdit) {
          const deliveryId = to.params.idDelivery

          syslic
            .order
            .delivery
            .fetchDelivery(deliveryId)
            .then(response => {
              const delivery = response.data

              if (delivery.situation === 'audit' || delivery.situation === 'done') {
                next()
              } else {
                next({
                  name: 'delivery-edit',
                  params: {
                    id: to.params.id,
                    idDelivery: deliveryId,
                  },
                })
              }
            })
            .catch(() => {
              next({ name: 'delivery' })
            })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/order/:id/delivery/edit/:idDelivery',
    name: 'delivery-edit',
    component: () => import('@/views/order/delivery/DeliveryEdit.vue'),
    meta: {
      pageTitle: 'Entregas',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Entregas',
          toName: 'delivery',
        },
        {
          prefix: '#',
          routerParam: 'idDelivery',
          toName: 'delivery-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'update',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
