import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class DocumentType {
  constructor() {
    log.info('[INFO] Initialize Document Type Module')
    this.cache = new Cache()
  }

  fetchDocumentTypes(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/contract/type/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch document types')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch document types')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchDocumentType(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/contract/type/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch document type')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch document type')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateDocumentType(id, documentTypeData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/contract/type/${id}/`, documentTypeData)
        .then(response => {
          log.debug('[DEBUG] Success to update document type')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update document type')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addDocumentType(documentTypeData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/contract/type/', documentTypeData)
        .then(response => {
          log.debug('[DEBUG] Success to add document type')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add document type')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteDocumentType(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/contract/type/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete document type')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete document type')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
