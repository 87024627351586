import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class HiringModalitie {
  constructor() {
    log.info('[INFO] Initialize HiringModalitie Module')
  }

  fetchHiringModalities(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/pncp/hiring-modalitie/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch hiring modalities')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch hiring modalities')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHiringModalitie(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/pncp/hiring-modalitie/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch hiring modalitie')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch hiring modalitie')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateHiringModalitie(id, hiringModalitieData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/pncp/hiring-modalitie/${id}/`, hiringModalitieData)
        .then(response => {
          log.debug('[DEBUG] Success to update hiring modalitie')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update hiring modalitie')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addHiringModalitie(hiringModalitieData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/pncp/hiring-modalitie/', hiringModalitieData)
        .then(response => {
          log.debug('[DEBUG] Success to add hiring modalitie')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add hiring modalitie')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteHiringModalitie(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/pncp/hiring-modalitie/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete hiring modalitie')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete hiring modalitie')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
