import log from 'loglevel'
import axios from 'axios'

import Visualization from './visualization'

/* eslint class-methods-use-this: ["off"] */

export default class Messager {
  constructor() {
    log.info('[INFO] Initialize Messager Module')

    this.visualization = new Visualization()
  }

  fetchMessages(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/messager/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch messages')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch messages')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchMessage(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/messager/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch message')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch message')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateMessage(id, messageData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/messager/${id}/`, messageData)
        .then(response => {
          log.debug('[DEBUG] Success to update message')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update message')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addMessage(messageData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/messager/', messageData)
        .then(response => {
          log.debug('[DEBUG] Success to add message')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add message')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteMessage(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/messager/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete message')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete message')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
