import log from 'loglevel'
import axios from 'axios'
import CompanyFile from './file'
import CompanyFileDetail from './fileDetail'
import CompanyCertificate from './certificate'
import CompanyCertificateFile from './certificateFile'
import CompanyCertificateStatus from './certificateStatus'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Company {
  constructor() {
    log.info('[INFO] Initialize Company Module')
    this.cache = new Cache()

    this.file = new CompanyFile()
    this.fileDetail = new CompanyFileDetail()
    this.certificate = new CompanyCertificate()
    this.certificateFile = new CompanyCertificateFile()
    this.certificateStatus = new CompanyCertificateStatus()
  }

  fetchCompanyList(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/company/company-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch company list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch company list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchCompanies(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/company/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch companies')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch companies')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchCompany(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/company/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch company')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch company')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateCompany(id, companyData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/company/${id}/`, companyData)
        .then(response => {
          log.debug('[DEBUG] Success to update company')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update company')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addCompany(companyData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/company/', companyData)
        .then(response => {
          log.debug('[DEBUG] Success to add company')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add company')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteCompany(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/company/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete company')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete company')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
