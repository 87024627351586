import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

import syslic from '@/syslic'

export default [
  {
    path: '/invoicing',
    name: 'invoicing',
    component: () => import('@/views/order/invoicing/InvoicingList.vue'),
    meta: {
      pageTitle: 'Faturamentos',
      breadcrumb: [
        {
          text: 'Faturamentos',
          toName: 'invoicing',
        },
        {
          text: 'Todos os Faturamentos',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/invoicing/view/:id',
    name: 'invoicing-detail',
    component: () => import('@/views/order/invoicing/InvoicingEdit.vue'),
    meta: {
      pageTitle: 'Faturamentos',
      breadcrumb: [
        {
          text: 'Todos os Faturamentos',
          toName: 'invoicing',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'invoicing-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
      beforeEnter: (to, from, next) => {
        const canEdit = to.meta.permissions.can_edit

        if (canEdit) {
          const invoicingId = to.params.id

          syslic
            .order
            .invoicing
            .fetchInvoicing(invoicingId)
            .then(response => {
              const invoicing = response.data

              if (invoicing.situation === 'done') {
                next()
              } else {
                next({
                  name: 'invoicing-edit',
                  params: {
                    id: invoicingId,
                  },
                })
              }
            })
            .catch(() => {
              next({ name: 'invoicing' })
            })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/invoicing/edit/:id',
    name: 'invoicing-edit',
    component: () => import('@/views/order/invoicing/InvoicingEdit.vue'),
    meta: {
      pageTitle: 'Faturamentos',
      breadcrumb: [
        {
          text: 'Todos os Faturamentos',
          toName: 'invoicing',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'invoicing-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'update',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
