import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class History {
  constructor() {
    log.info('[INFO] Initialize History Module')
    this.cache = new Cache()
  }

  fetchAllHistory(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/history/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch history')
            resolve(response.data)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch history')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch history')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
