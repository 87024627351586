import syslic from '@/syslic'

const createRule = (action, subject) => ({
  action: `${action}`,
  subject: `${subject}`,
})

const modules = {
  admin: 'Admin',
  user: 'User',
  permission: 'Permission',
  company: 'Company',
  client: 'Client',
  bidding: 'Bidding',
  bidding_settings: 'BiddingSettings',
  supplier: 'Supplier',
  supplier_settings: 'SupplierSettings',
  product: 'Product',
  product_settings: 'ProductSettings',
  transport: 'Transport',
  transport_settings: 'TransportSettings',
  order: 'Order',
  order_settings: 'OrderSettings',
  contract: 'Contract',
  contract_settings: 'ContractSettings',
  commission: 'Commission',
  report: 'Report',
}

export const getModuleName = module => {
  const reversedModules = Object.entries(modules).reduce((acc, [key, value]) => {
    acc[value] = key
    return acc
  }, {})

  return modules[module] || reversedModules[module] || module
}

export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const updateAbility = () => {
  const profile = syslic.authentication.getProfile()
  if (profile === null) {
    return []
  }
  const permission = profile.get_permissions_for_modules
  const ability = []

  ability.push(createRule('read', 'Auth'))

  let isHomeAccount = true

  if (profile.account !== profile.context_account) {
    isHomeAccount = false
  }

  Object.keys(permission).forEach(row => {
    if (!(isHomeAccount === false && row === 'admin')) {
      if (permission[row].can_read) {
        ability.push(createRule('read', getModuleName(row)))
      }
      if (permission[row].can_write) {
        ability.push(createRule('create', getModuleName(row)))
      }
      if (permission[row].can_edit) {
        ability.push(createRule('update', getModuleName(row)))
      }
      if (permission[row].can_delete) {
        ability.push(createRule('delete', getModuleName(row)))
      }
    }
  })
  return ability
}

export const _ = undefined
