import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Homologation {
  constructor() {
    log.info('[INFO] Initialize Homologation Module')
  }

  updateHomologation(id, value) {
    const homologationData = {
      is_homologated: value,
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/homologated/${id}/`, homologationData)
        .then(response => {
          log.debug('[DEBUG] Success to update homologation')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update homologation')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
