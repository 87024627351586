import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class User {
  constructor() {
    log.info('[INFO] Initialize User Module')
    this.cache = new Cache()
  }

  fetchUsers(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
      if ('is_active' in params) {
        parameters.params.is_active = params.is_active
      }
      if ('is_admin' in params) {
        parameters.params.profile__is_admin = params.is_admin
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/accounts/user/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch users')
            resolve(response)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch users')
            log.error(err)
            reject(err.response)
          })
      })
      return promise
    })
    return result
  }

  fetchUsersList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
      if ('is_active' in params) {
        parameters.params.is_active = params.is_active
      }
      if ('is_admin' in params) {
        parameters.params.profile__is_admin = params.is_admin
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/accounts/user-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch users')
            resolve(response)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch users')
            log.error(err)
            reject(err.response)
          })
      })
      return promise
    })
    return result
  }

  fetchUser(id) {
    const result = this.cache.bind(id, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get(`v1/accounts/user-profile/${id}/`)
          .then(response => {
            log.debug('[DEBUG] Success to fetch user')
            resolve(response)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch user')
            log.error(err)
            reject(err.response)
          })
      })
      return promise
    })
    return result
  }

  fetchUserBasic(id) {
    const result = this.cache.bind(id, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get(`v1/accounts/user-profile-basic/${id}/`)
          .then(response => {
            log.debug('[DEBUG] Success to fetch user')
            resolve(response)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch user')
            log.error(err)
            reject(err.response)
          })
      })
      return promise
    })
    return result
  }

  updateUser(id, userData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/user-profile/${id}/`, userData)
        .then(response => {
          log.debug('[DEBUG] Success to update user')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update user')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateUserIsAdmin(id, userData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/profile-is-admin/${id}/`, userData)
        .then(response => {
          log.debug('[DEBUG] Success to update user is admin')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update user is admin')
          log.error(err)
          reject(err.response)
        })
    })
  }

  getUserImage(id) {
    const result = this.cache.bind(id, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get(`v1/accounts/user/photo/${id}/`)
          .then(response => {
            log.debug('[DEBUG] Success to get user photo')
            const image = `data:image/jpg;base64,${response.data.image}`
            resolve(image)
          })
          .catch(err => {
            log.error('[ERROR] Fail to get user photo')
            log.error(err)
            reject(err.response)
          })
      })
      return promise
    })
    return result
  }

  updateUserImage(id, image) {
    const payload = { image }
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/user/photo/${id}/`, payload)
        .then(response => {
          log.debug('[DEBUG] Success to update user photo')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update user photo')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteUserImage(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/accounts/user/photo/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete user photo')
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete user photo')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateUserPassword(id, newPassword) {
    const data = { new_password: newPassword }

    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/user-change-password/${id}/`, data)
        .then(response => {
          log.debug('[DEBUG] Success to update user password')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update user password')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addUser(userData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/accounts/user/', userData)
        .then(response => {
          log.debug('[DEBUG] Success to add user')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add user')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteUser(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/accounts/user-profile/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete user with id:', id)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete user')
          log.error(err)
          reject(err.response)
        })
    })
  }

  activateUser(id, activate) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/user/activate/${id}/`, { is_active: activate })
        .then(() => {
          log.debug('[DEBUG] Success to activate/deactivate user with id:', id)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to activate/deactivate user')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchAuthors(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
        },
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/accounts/author/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch authors')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch authors')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchAuthor(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/accounts/author/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch author')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch author')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
