import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Certificate {
  constructor() {
    log.info('[INFO] Initialize Certificate Module')
  }

  fetchAllCertificate(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/company/certificate/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch certificate')
          resolve(response.data)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch certificate')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchCertificate(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/company/certificate/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch certificate')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch certificate')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateCertificate(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/company/certificate/${id}/`, data)
        .then(response => {
          log.debug('[DEBUG] Success to update certificate')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update certificate')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addCertificate(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/company/certificate/', data)
        .then(response => {
          log.debug('[DEBUG] Success to add certificate')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add certificate')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteCertificate(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/company/certificate/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete certificate')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete certificate')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
