import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

import settings from './settings'

export default [
  ...settings,

  {
    path: '/contract',
    name: 'contract',
    component: () => import('@/views/contract/ContractList.vue'),
    meta: {
      pageTitle: 'Contratos',
      breadcrumb: [
        {
          text: 'Contratos',
          toName: 'contract',
        },
        {
          text: 'Todos os Contratos',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Contract',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/contract/view/:id',
    name: 'contract-detail',
    component: () => import('@/views/contract/ContractEdit.vue'),
    meta: {
      pageTitle: 'Contratos',
      breadcrumb: [
        {
          text: 'Contratos',
          toName: 'contract',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'contract-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Contract',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'contract-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
  {
    path: '/contract/edit/:id',
    name: 'contract-edit',
    component: () => import('@/views/contract/ContractEdit.vue'),
    meta: {
      pageTitle: 'Contratos',
      breadcrumb: [
        {
          text: 'Contratos',
          toName: 'contract',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'contract-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Contract',
      action: 'update',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
