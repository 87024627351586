import log from 'loglevel'
import axios from 'axios'
import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Freight {
  constructor() {
    log.info('[INFO] Initialize Freight Sub-Module')
    this.cache = new Cache()
  }

  fetchFreights(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/transport/freight/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch freights')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch freights')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchFreight(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/transport/freight/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch freight')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch freight')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateFreight(id, freightData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/transport/freight/${id}/`, freightData)
        .then(response => {
          log.debug('[DEBUG] Success to update freight')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update freight')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addFreight(freightData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/transport/freight/', freightData)
        .then(response => {
          log.debug('[DEBUG] Success to add freight')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add freight')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteFreight(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/transport/freight/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete freight')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete freight')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
