import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class OrderItem {
  constructor() {
    log.info('[INFO] Initialize Order Item Module')
  }

  getItemReport(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/items/report/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to get order item report')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to get order item report')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
