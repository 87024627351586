import profile from './profile'
import account from './account'

export default [
  ...profile,
  ...account,
  {
    path: '/settings/administrator/',
    name: 'administrators',
    component: () => import('@/views/administrator/administrator/AdministratorList.vue'),
    meta: {
      pageTitle: 'Administradores',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'administrators',
        },
        {
          text: 'Sistema',
          toName: 'administrators',
        },
        {
          text: 'Administradores',
          toName: 'administrators',
        },
      ],
      requireLogin: true,
      resource: 'Admin',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
