/* eslint class-methods-use-this: ["off"] */

export default class Cache {
  constructor() {
    this.cache = {}
    this.promises = {}
  }

  bind(keyObj, timeout, callback) {
    const key = JSON.stringify({ data: keyObj, callPoint: this.getCallPoint() })

    if (Object.keys(this.cache).includes(key)) {
      return new Promise(resolve => {
        resolve(this.cache[key])
      })
    }

    if (Object.keys(this.promises).includes(key)) {
      return this.promises[key]
    }

    return new Promise((resolve, reject) => {
      const promiseLoadCache = callback()

      promiseLoadCache
        .then(response => {
          this.cache[key] = response
          resolve(response)

          this.scheduleClean(key, timeout)
        })
        .catch(err => {
          reject(err)
        })

      this.promises[key] = promiseLoadCache
    })
  }

  scheduleClean(key, timeout) {
    setTimeout(() => {
      delete this.promises[key]
      delete this.cache[key]
    }, timeout * 1000)
  }

  getCallPoint() {
    let prefix = ''
    try {
      throw new Error()
    } catch (e) {
      const stackLines = e.stack.split('\n')

      /* eslint prefer-destructuring: ["off"] */
      prefix = stackLines[3]
    }
    return prefix
  }

  reset() {
    this.cache = {}
    this.promises = {}
  }
}
