import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Scope {
  constructor() {
    log.info('[INFO] Initialize Scope Module')
    this.cache = new Cache()
  }

  fetchScopes(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/contract/scope/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch scopes')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch scopes')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchScope(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/contract/scope/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch scope')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch scope')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateScope(id, scopeData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/contract/scope/${id}/`, scopeData)
        .then(response => {
          log.debug('[DEBUG] Success to update scope')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update scope')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addScope(scopeData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/contract/scope/', scopeData)
        .then(response => {
          log.debug('[DEBUG] Success to add scope')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add scope')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteScope(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/contract/scope/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete scope')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete scope')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
