import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

export default [
  {
    path: '/commitment',
    name: 'commitment',
    component: () => import('@/views/order/commitment/CommitmentList.vue'),
    meta: {
      pageTitle: 'Empenhos',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          text: 'Todos os Empenhos',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
