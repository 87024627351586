import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class CommitmentStatus {
  constructor() {
    log.info('[INFO] Initialize Commitment Status Module')
  }

  fetchAllCommitmentStatus(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/order/commitment/status/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch commitment status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch commitments')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchCommitmentStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/commitment/status/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch commitment status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch commitment status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateCommitmentStatus(id, commitmentData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/commitment/status/${id}/`, commitmentData)
        .then(response => {
          log.debug('[DEBUG] Success to update commitment status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update commitment status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addCommitmentStatus(commitmentData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/commitment/status/', commitmentData)
        .then(response => {
          log.debug('[DEBUG] Success to add commitment status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add commitment status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteCommitmentStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/commitment/status/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete commitment status')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete commitment status')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
