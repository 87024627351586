import log from 'loglevel'
import axios from 'axios'

import Cache from '../../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class AccountProfile {
  constructor() {
    log.info('[INFO] Initialize Account Profile Module')
    this.cache = new Cache()
  }

  fetchAccountProfileList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/accounts/account-profile-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch Account Profiles')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch Account Profiles')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchAccountProfileSelectList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/accounts/account-profile-select/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch Account Profiles Select')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch Account Profiles Select')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchAccountProfile(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/accounts/account-profile/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch Account Profile')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch Account Profile')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateAccountProfile(id, administratorData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/account-profile/${id}/`, administratorData)
        .then(response => {
          log.debug('[DEBUG] Success to update Account Profile')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update Account Profile')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addAccountProfile(administratorData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/accounts/account-profile/', administratorData)
        .then(response => {
          log.debug('[DEBUG] Success to add Account Profile')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add Account Profile')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteAccountProfile(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/accounts/account-profile/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete Account Profile')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete Account Profile')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
