import log from 'loglevel'
import axios from 'axios'
import Cache from '../../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class InvoicingStatus {
  constructor() {
    log.info('[INFO] Initialize Invoicing Status Module')
    this.cache = new Cache()
  }

  fetchAllInvoicingStatus(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/invoicing/status/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch invoicing status')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch invoicing')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchInvoicingStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/invoicing/status/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch invoicing status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch invoicing status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateInvoicingStatus(id, commitmentData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/invoicing/status/${id}/`, commitmentData)
        .then(response => {
          log.debug('[DEBUG] Success to update invoicing status')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update invoicing status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addInvoicingStatus(commitmentData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/invoicing/status/', commitmentData)
        .then(response => {
          log.debug('[DEBUG] Success to add invoicing status')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add invoicing status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteInvoicingStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/invoicing/status/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete invoicing status')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete invoicing status')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
