import {
  mdiClipboardEditOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

export default [
  {
    path: '/client',
    name: 'client',
    component: () => import('@/views/client/ClientList.vue'),
    meta: {
      pageTitle: 'Órgãos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'client',
        },
        {
          text: 'Órgãos',
          toName: 'client',
        },
      ],
      requireLogin: true,
      resource: 'Client',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/client/view/:id',
    name: 'client-detail',
    component: () => import('@/views/client/ClientEdit.vue'),
    meta: {
      pageTitle: 'Órgãos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'client',
        },
        {
          text: 'Órgãos',
          toName: 'client',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'client-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Client',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'client-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
  {
    path: '/client/create/',
    name: 'client-create',
    component: () => import('@/views/client/ClientEdit.vue'),
    meta: {
      pageTitle: 'Órgãos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'client',
        },
        {
          text: 'Órgãos',
          toName: 'client',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Client',
      action: 'create',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/client/edit/:id',
    name: 'client-edit',
    component: () => import('@/views/client/ClientEdit.vue'),
    meta: {
      pageTitle: 'Órgãos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'client',
        },
        {
          text: 'Órgãos',
          toName: 'client',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'client-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Client',
      action: 'update',
      iconSvg: mdiClipboardEditOutline,
    },
  },
]
