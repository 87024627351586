import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import syslic from '@/syslic'
import { canNavigate } from '@/libs/acl/routeProtection'
import { getModuleName } from '@/libs/acl/config'

import access from '@/router/routes/access'
import assistance from './routes/assistance'
import user from '@/router/routes/user'
import company from '@/router/routes/company'
import client from '@/router/routes/client'
import bidding from '@/router/routes/bidding'
import supplier from '@/router/routes/supplier'
import product from '@/router/routes/product'
import transport from '@/router/routes/transport'
import order from '@/router/routes/order'
import commitment from '@/router/routes/commitment'
import delivery from '@/router/routes/delivery'
import priceRecords from '@/router/routes/price-records'
import contract from '@/router/routes/contract'
import commission from '@/router/routes/commission'
import report from '@/router/routes/report'
import invoicing from '@/router/routes/invoicing'
import administrator from '@/router/routes/administrator'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...user,
    ...assistance,
    ...access,
    ...company,
    ...client,
    ...bidding,
    ...supplier,
    ...product,
    ...transport,
    ...order,
    ...commitment,
    ...delivery,
    ...priceRecords,
    ...contract,
    ...commission,
    ...report,
    ...invoicing,
    ...administrator,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!canNavigate(to) && to.path !== '/error-404' && to.path !== '/login') {
    if (
      to.matched.some(record => record.meta.requireLogin)
      && !syslic.authentication.isAuthenticated()
    ) {
      syslic.authentication.logoff()
      next('/login')
    } else {
      next('/error-404')
    }
  } else if (
    to.matched.some(record => record.meta.blockedAfterLogin)
    && syslic.authentication.isAuthenticated() && to.path === '/login'
  ) {
    next('/')
  } else if (to.meta.requireLogin && !syslic.authentication.isAuthenticated()) {
    syslic.authentication.logoff()
    next('/login')
  } else {
    if ('resource' in to.meta) {
      const module = getModuleName(to.meta.resource)
      const profile = syslic.authentication.getProfile()
      /* eslint-disable-next-line */
      to.meta.permissions = profile.get_permissions_for_modules[module]
    }

    if (to.meta.beforeEnter) {
      to.meta.beforeEnter(to, from, next)
    } else {
      next()
    }
  }
})

axios.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    syslic.authentication.logoff()
    router.push({ name: 'login' })
  }
  return Promise.reject(error)
})

export default router
