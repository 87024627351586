import log from 'loglevel'
import axios from 'axios'

import HiringModalitie from './hiringModalitie'
import Powers from './powers'
import Spheres from './spheres'
import TypeCallingInstrument from './typeCallingInstrument'
import Cities from './cities'
import Units from './units'
import Organs from './organs'
import Filter from './filter'

/* eslint class-methods-use-this: ["off"] */

export default class Pncp {
  constructor() {
    log.info('[INFO] Initialize Pncp Module')

    this.hiringModalitie = new HiringModalitie()
    this.powers = new Powers()
    this.spheres = new Spheres()
    this.typeCallingInstrument = new TypeCallingInstrument()
    this.cities = new Cities()
    this.units = new Units()
    this.organs = new Organs()
    this.filter = new Filter()

    this.axios = axios.create({
      baseURL: 'https://pncp.gov.br/api/',
      timeout: 20000,
    })

    this.axios.defaults.headers.common.Authorization = ''
  }

  fetchBiddings(page, pageSize, query, docType, ordering, status, modalidades, ufs, esferas, poderes, tipos, orgaos, unidades, municipios) {
    const parameters = {
      params: {
        pagina: page,
        tam_pagina: pageSize,
        q: query,
        tipos_documento: docType,
        ordenacao: ordering,
        status,
        modalidades,
        ufs,
        esferas,
        poderes,
        tipos,
        orgaos,
        unidades,
        municipios,
      },
    }

    return new Promise((resolve, reject) => {
      this.axios
        .get('search/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to biddings from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch biddings from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchBidding(cnpj, year, sequenceNumber) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`consulta/v1/orgaos/${cnpj}/compras/${year}/${sequenceNumber}`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding from pncp')
          log.error(error)
          reject(error)
        })
    })
  }

  fetchBiddingItemsAmount(cnpj, year, sequenceNumber) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`pncp/v1/orgaos/${cnpj}/compras/${year}/${sequenceNumber}/itens/quantidade`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding items amount from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding items amount from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchBiddingItemsPage(cnpj, year, sequenceNumber, page, perPage) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`pncp/v1/orgaos/${cnpj}/compras/${year}/${sequenceNumber}/itens?pagina=${page}&tamanhoPagina=${perPage}`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding items page from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding items page from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchBiddingItems(cnpj, year, sequenceNumber) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`pncp/v1/orgaos/${cnpj}/compras/${year}/${sequenceNumber}/itens`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding items from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding items from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchBiddingFiles(cnpj, year, sequenceNumber) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`pncp/v1/orgaos/${cnpj}/compras/${year}/${sequenceNumber}/arquivos`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding files from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding files from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }

  importBidding(biddingData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/import/', biddingData)
        .then(response => {
          log.debug('[DEBUG] Success to import bidding from pncp')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to import bidding from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchBiddingsImported(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/bidding/imported/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch imported biddings')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch imported biddings')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchBiddingsImportedSimple(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/bidding/imported/simple/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch imported simple biddings')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch imported simple biddings')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
