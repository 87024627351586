import log from 'loglevel'
import axios from 'axios'
import Authentication from './authentication'
import User from './user'
import Permission from './permission'
import Address from './address'
import Company from './company'
import Client from './client'
import Bidding from './bidding'
import Supplier from './supplier'
import Product from './product'
import Transport from './transport'
import Contact from './contact'
import Order from './order'
import Messager from './messager'
import Contract from './contract'
import Pncp from './pncp'
import Commission from './commission'
import MetaData from './metadata'
import Superset from './superset'
import Report from './report'
import Guru from './guru'
import Administrator from './administrator'

/* eslint no-underscore-dangle: ["error", { "allow": ["_instance"] }] */

const FRONTEND_VERSION = '1.8.1'

const FRONTEND_API_ADDRESS = process.env.VUE_APP_API_URL || 'http://localhost:8080/lab/syslic/api/'

class SysLic {
  constructor() {
    if (!SysLic._instance) {
      SysLic._instance = this

      // Setup log
      log.enableAll()
      log.info('[INFO] Initialize SysLic API')

      // Setup API address
      this.version = FRONTEND_VERSION
      this.address = FRONTEND_API_ADDRESS

      axios.defaults.baseURL = this.address
      log.info(`[INFO] API address: ${this.address}`)

      // Initilize Modules
      this.authentication = new Authentication()
      this.user = new User()
      this.permission = new Permission()
      this.address = new Address()
      this.company = new Company()
      this.client = new Client()
      this.bidding = new Bidding()
      this.supplier = new Supplier()
      this.product = new Product()
      this.transport = new Transport()
      this.contact = new Contact()
      this.order = new Order()
      this.messager = new Messager()
      this.contract = new Contract()
      this.pncp = new Pncp()
      this.commission = new Commission()
      this.metadata = new MetaData()
      this.superset = new Superset()
      this.report = new Report()
      this.guru = new Guru()
      this.administrator = new Administrator()
    }
    return SysLic._instance
  }
}

export default new SysLic()
