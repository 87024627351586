export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'user',
        },
        {
          text: 'Acessos',
          toName: 'user',
        },
        {
          text: 'Usuários',
          toName: 'user',
        },
      ],
      requireLogin: true,
      resource: 'User',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/user/create/',
    name: 'user-create',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'user',
        },
        {
          text: 'Acessos',
          toName: 'user',
        },
        {
          text: 'Usuários',
          toName: 'user',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'User',
      action: 'create',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/user/view/me',
    name: 'profile-detail',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Perfil',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'profile-detail',
        },
        {
          text: 'Meu Perfil',
          toName: 'profile-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Auth',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/user/edit/me',
    name: 'profile-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Perfil',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'profile-detail',
        },
        {
          text: 'Meu Perfil',
          toName: 'profile-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Auth',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/user/view/:id',
    name: 'user-detail',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Usuário',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'user',
        },
        {
          text: 'Acessos',
          toName: 'user',
        },
        {
          text: 'Usuários',
          toName: 'user',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'User',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'user',
        },
        {
          text: 'Acessos',
          toName: 'user',
        },
        {
          text: 'Usuários',
          toName: 'user',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'User',
      action: 'update',
      icon: 'SettingsIcon',
    },
  },
]
