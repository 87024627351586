import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

const SUPERSET_SERVER_ADDRESS = 'http://127.0.0.1:8088'
const SUPERSET_CREDENTIALS_USERNAME = 'syslic'
const SUPERSET_CREDENTIALS_PASSWORD = 'syslic'
const SUPERSET_CREDENTIALS_FIRSTNAME = 'SysLic'
const SUPERSET_CREDENTIALS_LASTNAME = 'User'

export default class Superset {
  constructor() {
    log.info('[INFO] Initialize Superset Module')

    this.SUPERSET_API_ADDRESS = `${SUPERSET_SERVER_ADDRESS}/api/`

    this.axios = axios.create({
      baseURL: this.SUPERSET_API_ADDRESS,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      },
    })

    this.axios.defaults.headers.common.Authorization = ''
  }

  getDomain() {
    return SUPERSET_SERVER_ADDRESS
  }

  login() {
    const data = {
      username: SUPERSET_CREDENTIALS_USERNAME,
      password: SUPERSET_CREDENTIALS_PASSWORD,
      provider: 'db',
      refresh: true,
    }

    return new Promise((resolve, reject) => {
      this.axios
        .post('v1/security/login', data)
        .then(response => {
          const token = response.data.access_token

          if (token) {
            this.axios.defaults.headers.common.Authorization = `Bearer ${token}`
          } else {
            this.axios.defaults.headers.common.Authorization = ''
          }
          log.debug('[DEBUG] Success to login on superset')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to login on superset')
          log.error(error)
          reject()
        })
    })
  }

  getToken(dashboard) {
    const data = {
      user: {
        username: SUPERSET_CREDENTIALS_USERNAME,
        first_name: SUPERSET_CREDENTIALS_FIRSTNAME,
        last_name: SUPERSET_CREDENTIALS_LASTNAME,
      },
      resources: [
        {
          id: dashboard,
          type: 'dashboard',
        },
      ],
      rls: [],
    }

    return new Promise((resolve, reject) => {
      this.login()
        .then(() => {
          this.axios
            .post('v1/security/guest_token/', data)
            .then(response => {
              log.debug('[DEBUG] Success to fetch superset token')
              resolve(response.data.token)
            })
            .catch(error => {
              log.error('[ERROR] Fail to fetch superset token')
              log.error(error)
              reject(error.response)
            })
        })
    })
  }
}
