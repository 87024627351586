import log from 'loglevel'
import axios from 'axios'
import ItemList from './itemList'
import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Item {
  constructor() {
    log.info('[INFO] Initialize Item SubModule')
    this.cache = new Cache()

    this.list = new ItemList()
  }

  fetchItemsList(params) {
    let parameters = {}

    if (params) {
      parameters = { params }

      if ('id' in params) {
        parameters.params.id = params.id
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/product/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch item list')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch item list')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchItems(params) {
    let parameters = {}

    if (params) {
      parameters = { params }

      if ('id' in params) {
        parameters.params.id = params.id
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/product/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch item')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch item')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch item')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch item')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateItem(id, itemData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/product/${id}/`, itemData)
        .then(response => {
          log.debug('[DEBUG] Success to update item')
          this.cache.reset()
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update item')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addItem(itemData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/product/', itemData)
        .then(response => {
          log.debug('[DEBUG] Success to add item')
          this.cache.reset()
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add item')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/product/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete item with id:${id}`)
          this.cache.reset()
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete item')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
