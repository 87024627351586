import log from 'loglevel'
import axios from 'axios'
import DocumentType from './documentType'
import Scope from './scope'
import Status from './status'
import File from './file'
import Item from './item'
import Filter from './filter'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Contract {
  constructor() {
    log.info('[INFO] Initialize Contract Module')
    this.documentType = new DocumentType()
    this.scope = new Scope()
    this.status = new Status()
    this.file = new File()
    this.item = new Item()
    this.filter = new Filter()

    this.cache = new Cache()
  }

  fetchContractList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/contract/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch contract list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch contract list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchContracts(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/contract/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch contracts')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch contracts')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchContract(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/contract/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch contract')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch contract')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateContract(id, contractData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/contract/${id}/`, contractData)
        .then(response => {
          log.debug('[DEBUG] Success to update contract')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update contract')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateContractPatch(id, contractData) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`v1/contract/${id}/`, contractData)
        .then(response => {
          log.debug('[DEBUG] Success to update contract')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update contract')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addContract(contractData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/contract/', contractData)
        .then(response => {
          log.debug('[DEBUG] Success to add contract')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add contract')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteContract(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/contract/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete contract')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete contract')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/contract/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch contract history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch contract history')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
