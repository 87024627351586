import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class File {
  constructor() {
    log.info('[INFO] Initialize File Module')
  }

  fetchFiles(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/order/commitment/attach/file/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch files')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch files')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchFile(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/commitment/attach/file/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch file')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch file')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateFile(id, fileData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/commitment/attach/file/${id}/`, fileData)
        .then(response => {
          log.debug('[DEBUG] Success to update file')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update file')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addFile(fileData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/commitment/attach/file/', fileData)
        .then(response => {
          log.debug('[DEBUG] Success to add file')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add file')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteFile(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/commitment/attach/file/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete file')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete file')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
