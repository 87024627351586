import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Item {
  constructor() {
    log.info('[INFO] Initialize Item Module')
    this.cache = new Cache()
  }

  fetchItems(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/item/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch items')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch items')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/item/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch item')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateItem(id, itemData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/item/${id}/`, itemData)
        .then(response => {
          log.debug('[DEBUG] Success to update item')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addItem(itemData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/item/', itemData)
        .then(response => {
          log.debug('[DEBUG] Success to add item')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/bidding/item/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete item')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateItemResult(id, itemResultData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/item/result/${id}/`, itemResultData)
        .then(response => {
          log.debug('[DEBUG] Success to update item result')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update item result')
          log.error(error)
          reject(error.response)
        })
    })
  }

  convertItemType(id, itemTypeData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/item/type/convert/${id}/`, itemTypeData)
        .then(response => {
          log.debug('[DEBUG] Success to convert item type')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to convert item type')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
