export default [
  {
    path: '/settings/bidding/modality',
    name: 'bidding-modality',
    component: () => import('@/views/bidding/settings/Modality.vue'),
    meta: {
      pageTitle: 'Modalidade',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-modality',
        },
        {
          text: 'Licitações',
          toName: 'bidding-modality',
        },
        {
          text: 'Modalidade',
          toName: 'bidding-modality',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/type',
    name: 'bidding-type',
    component: () => import('@/views/bidding/settings/Type.vue'),
    meta: {
      pageTitle: 'Tipo',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-type',
        },
        {
          text: 'Licitações',
          toName: 'bidding-type',
        },
        {
          text: 'Tipo',
          toName: 'bidding-type',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/interest',
    name: 'bidding-interest',
    component: () => import('@/views/bidding/settings/Interest.vue'),
    meta: {
      pageTitle: 'Interesse',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-interest',
        },
        {
          text: 'Licitações',
          toName: 'bidding-interest',
        },
        {
          text: 'Interesse',
          toName: 'bidding-interest',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/platform',
    name: 'bidding-platform',
    component: () => import('@/views/bidding/settings/Platform.vue'),
    meta: {
      pageTitle: 'Plataforma',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-platform',
        },
        {
          text: 'Licitações',
          toName: 'bidding-platform',
        },
        {
          text: 'Plataforma',
          toName: 'bidding-platform',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/platform/view/:id',
    name: 'bidding-platform-detail',
    component: () => import('@/views/bidding/settings/PlatformEdit.vue'),
    meta: {
      pageTitle: 'Plataforma',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-platform',
        },
        {
          text: 'Licitações',
          toName: 'bidding-platform',
        },
        {
          text: 'Plataforma',
          toName: 'bidding-platform',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'bidding-platform-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/platform/create/',
    name: 'bidding-platform-create',
    component: () => import('@/views/bidding/settings/PlatformEdit.vue'),
    meta: {
      pageTitle: 'Plataforma',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-platform',
        },
        {
          text: 'Licitações',
          toName: 'bidding-platform',
        },
        {
          text: 'Plataforma',
          toName: 'bidding-platform',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'create',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/platform/edit/:id',
    name: 'bidding-platform-edit',
    component: () => import('@/views/bidding/settings/PlatformEdit.vue'),
    meta: {
      pageTitle: 'Plataforma',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-platform',
        },
        {
          text: 'Licitações',
          toName: 'bidding-platform',
        },
        {
          text: 'Plataforma',
          toName: 'bidding-platform',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'bidding-platform-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'update',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/phase',
    name: 'bidding-phase',
    component: () => import('@/views/bidding/settings/Phase.vue'),
    meta: {
      pageTitle: 'Fases',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-phase',
        },
        {
          text: 'Licitações',
          toName: 'bidding-phase',
        },
        {
          text: 'Fases',
          toName: 'bidding-phase',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/status',
    name: 'bidding-status',
    component: () => import('@/views/bidding/settings/Status.vue'),
    meta: {
      pageTitle: 'Status',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-status',
        },
        {
          text: 'Licitações',
          toName: 'bidding-status',
        },
        {
          text: 'Status',
          toName: 'bidding-status',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/dispute',
    name: 'bidding-dispute',
    component: () => import('@/views/bidding/settings/Dispute.vue'),
    meta: {
      pageTitle: 'Disputa',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-dispute',
        },
        {
          text: 'Licitações',
          toName: 'bidding-dispute',
        },
        {
          text: 'Disputa',
          toName: 'bidding-dispute',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/bidding/requirements',
    name: 'bidding-requirements',
    component: () => import('@/views/bidding/settings/Requirement.vue'),
    meta: {
      pageTitle: 'Exigências',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'bidding-requirements',
        },
        {
          text: 'Licitações',
          toName: 'bidding-requirements',
        },
        {
          text: 'Exigências',
          toName: 'bidding-requirements',
        },
      ],
      requireLogin: true,
      resource: 'BiddingSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
