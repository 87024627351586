import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

import settings from './settings'
import commitment from './commitment'

export default [
  ...settings,
  ...commitment,

  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/OrderList.vue'),
    meta: {
      pageTitle: 'Pedidos',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          text: 'Todos os Pedidos',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/order/view/:id',
    name: 'order-detail',
    component: () => import('@/views/order/OrderEdit.vue'),
    meta: {
      pageTitle: 'Pedidos',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'order-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
  {
    path: '/order/edit/:id',
    name: 'order-edit',
    component: () => import('@/views/order/OrderEdit.vue'),
    meta: {
      pageTitle: 'Pedidos',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'update',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
