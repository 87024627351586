import log from 'loglevel'
import axios from 'axios'
import Cache from '../../utils/cache'

import CommitmentStatus from './status'
import CommitmentItem from './item'
import CommitmentFilter from './filter'
import CommitmentFile from './file'
import History from './history'

/* eslint class-methods-use-this: ["off"] */

export default class Commitment {
  constructor() {
    log.info('[INFO] Initialize Commitment Module')
    this.cache = new Cache()

    this.status = new CommitmentStatus()
    this.item = new CommitmentItem()
    this.filter = new CommitmentFilter()
    this.file = new CommitmentFile()
    this.history = new History()
  }

  fetchCommitmentList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/commitment/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch commitment list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch commitment list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchCommitments(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/commitment/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch commitments')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch commitments')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchCommitment(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/commitment/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch commitment')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch commitment')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateCommitment(id, commitmentData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/commitment/${id}/`, commitmentData)
        .then(response => {
          log.debug('[DEBUG] Success to update commitment')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update commitment')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addCommitment(commitmentData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/commitment/', commitmentData)
        .then(response => {
          log.debug('[DEBUG] Success to add commitment')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add commitment')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteCommitment(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/commitment/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete commitment')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete commitment')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/commitment/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch commitment history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch commitment history')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateSituation(id, value) {
    const commitmentIsDone = {
      situation: value,
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/commitment/situation/${id}/`, commitmentIsDone)
        .then(response => {
          log.debug('[DEBUG] Success to update situation')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update situation')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
