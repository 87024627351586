import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Address {
  constructor() {
    log.info('[INFO] Initialize Address Module')
    this.cache = new Cache()
  }

  fetchCountries(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/address/country/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch countries')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch countries')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchStates(params) {
    let parameters = {}

    if (params) {
      parameters = { params: {} }

      if ('p' in params) {
        parameters.params.p = params.p
      }

      if ('page_size' in params) {
        parameters.params.page_size = params.page_size
      }

      if ('q' in params) {
        parameters.params.search = params.q
      }

      if ('country' in params) {
        parameters.params.country = params.country
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/address/state/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch state')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch state')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchCities(params) {
    let parameters = {}

    if (params) {
      parameters = { params: {} }

      if ('p' in params) {
        parameters.params.p = params.p
      }

      if ('page_size' in params) {
        parameters.params.page_size = params.page_size
      }

      if ('q' in params) {
        parameters.params.search = params.q
      }

      if ('state' in params) {
        parameters.params.state = params.state
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/address/city/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch cities')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch cities')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchAddressTypes(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/address/address-type/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch address types')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch address types')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchNeighborhoodTypes(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/address/neighborhood-type/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch neighborhood types')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch neighborhood types')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }
}
