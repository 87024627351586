import {
  mdiChartBar,
} from '@mdi/js'

export default [
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report/ReportView.vue'),
    meta: {
      pageTitle: 'Relatórios',
      breadcrumb: [
        {
          text: 'Relatórios',
          toName: 'report',
        },
      ],
      requireLogin: true,
      resource: 'Auth',
      action: 'read',
      iconSvg: mdiChartBar,
    },
  },
]
