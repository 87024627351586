import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Dispute {
  constructor() {
    log.info('[INFO] Initialize Dispute Module')
    this.cache = new Cache()
  }

  fetchAllDispute(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/dispute/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch dispute')
            resolve(response.data)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch dispute')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchDispute(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/dispute/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch dispute')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch dispute')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateDispute(id, disputeData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/dispute/${id}/`, disputeData)
        .then(response => {
          log.debug('[DEBUG] Success to update dispute')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update dispute')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addDispute(disputeData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/dispute/', disputeData)
        .then(response => {
          log.debug('[DEBUG] Success to add dispute')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add dispute')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteDispute(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/bidding/dispute/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete dispute')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete dispute')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
