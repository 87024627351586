import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Client {
  constructor() {
    log.info('[INFO] Initialize Client Module')
  }

  fetchClientList(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/client/list/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch client list')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch client list')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchClients(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/client/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch clients')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch clients')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchClient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/client/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch client')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch client')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateClient(id, clientData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/client/${id}/`, clientData)
        .then(response => {
          log.debug('[DEBUG] Success to update client')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update client')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addClient(clientData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/client/', clientData)
        .then(response => {
          log.debug('[DEBUG] Success to add client')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add client')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteClient(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/client/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete client')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete client')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
