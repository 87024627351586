import log from 'loglevel'
import axios from 'axios'
import Cache from '../utils/cache'

import Freight from './freight'

/* eslint class-methods-use-this: ["off"] */

export default class Transport {
  constructor() {
    log.info('[INFO] Initialize Transport Module')
    this.cache = new Cache()

    this.freight = new Freight()
  }

  fetchTransportList(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/transport/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch transport list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch transport list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchTransports(params) {
    let parameters = {}

    if (params) {
      parameters = { params }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/transport/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch transports')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch transports')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchTransport(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/transport/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch transport')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch transport')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateTransport(id, transportData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/transport/${id}/`, transportData)
        .then(response => {
          log.debug('[DEBUG] Success to update transport')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update transport')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addTransport(transportData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/transport/', transportData)
        .then(response => {
          log.debug('[DEBUG] Success to add transport')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add transport')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteTransport(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/transport/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete transport')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete transport')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
