import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

export default [
  {
    path: '/records',
    name: 'records',
    component: () => import('@/views/bidding/price-records/PriceRecordsList.vue'),
    meta: {
      pageTitle: 'Registros de Preço',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'records',
        },
        {
          text: 'Registros de Preço',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/records/view/:id',
    name: 'records-view',
    component: () => import('@/views/bidding/price-records/PriceRecordsEdit.vue'),
    meta: {
      pageTitle: 'Registros de Preço',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'records',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'records-view',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/records/edit/:id',
    name: 'records-edit',
    component: () => import('@/views/bidding/price-records/PriceRecordsEdit.vue'),
    meta: {
      pageTitle: 'Registros de Preço',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'records',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'records-edit',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'update',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
