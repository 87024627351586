import log from 'loglevel'
import axios from 'axios'

import AccountProfile from './profile'
import Account from './account'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Administrator {
  constructor() {
    log.info('[INFO] Initialize Administrator Module')
    this.cache = new Cache()

    this.accountProfile = new AccountProfile()
    this.account = new Account()
  }

  fetchAdministratorList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    // CORRIGIR URL !!!
    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/accounts/account-profile-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch administrators')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch administrators')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  // CORRIGIR URL !!!
  fetchAdministrator(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/accounts/account-profile-list/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch administrator')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch administrator')
          log.error(error)
          reject(error.response)
        })
    })
  }

  // CORRIGIR URL !!!
  updateAdministrator(id, administratorData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/accounts/account-profile-list/${id}/`, administratorData)
        .then(response => {
          log.debug('[DEBUG] Success to update administrator')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update administrator')
          log.error(error)
          reject(error.response)
        })
    })
  }

  // CORRIGIR URL !!!
  addAdministrator(administratorData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/accounts/account-profile-list/', administratorData)
        .then(response => {
          log.debug('[DEBUG] Success to add administrator')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add administrator')
          log.error(error)
          reject(error.response)
        })
    })
  }

  // CORRIGIR URL !!!
  deleteAdministrator(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/accounts/account-profile-list/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete administrator')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete administrator')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
