import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class ContractItem {
  constructor() {
    log.info('[INFO] Initialize Contract Item Module')
  }

  getItems(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/contract/items/to/commitment/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to get contract item report')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to get contract item report')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateItem(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`v1/contract/item/${id}/`, params)
        .then(response => {
          log.debug('[DEBUG] Success to get contract item report')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to get contract item report')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
