import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Image {
  constructor() {
    log.info('[INFO] Initialize Image Module')
  }

  getDefaultImage(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/${id}/default_image/`)
        .then(response => {
          log.debug('[DEBUG] Success to get product photo')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to get product photo')
          log.error(err)
          reject(err.response)
        })
    })
  }

  setImage(id, image) {
    const payload = { image }
    return new Promise((resolve, reject) => {
      axios
        .post(`v1/product/${id}/album/`, payload)
        .then(response => {
          log.debug('[DEBUG] Success to set default product photo')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to set default product photo')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteImage(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/product/imagem/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete product photo')
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete product photo')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchImages(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/${id}/album/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch album')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch album')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
