import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Permission {
  constructor() {
    log.info('[INFO] Initialize Permission Module')
  }

  fetchProfilePermissions(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/permission/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch profile permissions')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch profile permissions')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchProfilePermissionList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/permission/permission-list/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch profile permission list')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch profile permission list')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchProfilePermission(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/permission/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch profile permission')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch profile permission')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateProfilePermission(id, profilePermissionData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/permission/${id}/`, profilePermissionData)
        .then(response => {
          log.debug('[DEBUG] Success to update profile permission')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update profile permission')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addProfilePermission(profilePermissionData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/permission/', profilePermissionData)
        .then(response => {
          log.debug('[DEBUG] Success to add profile permission')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add profile permission')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteProfilePermission(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/permission/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete profile permission')
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete profile permission')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addOrRemoveUserToProfilePermission(data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/permission/user-add-or-remove-profile/${data.id}/`, {
          user_uuid: data.user_uuid,
          to_remove: data.to_remove,
        })
        .then(() => {
          resolve()
          log.debug('[DEBUG] Success to add or remove user profile permission')
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to add or remove user profile permission')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
