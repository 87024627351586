import log from 'loglevel'
import axios from 'axios'

import Interest from './interest'
import Modality from './modality'
import Phase from './phase'
import Platform from './platform'
import Type from './type'
import Status from './status'
import Item from './item'
import Filter from './filter'
import File from './file'
import Dispute from './dispute'
import Requirements from './requirements'
import Homologation from './homologation'
import History from './history'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Bidding {
  constructor() {
    log.info('[INFO] Initialize Bidding Module')
    this.cache = new Cache()

    this.interest = new Interest()
    this.modality = new Modality()
    this.phase = new Phase()
    this.platform = new Platform()
    this.type = new Type()
    this.status = new Status()
    this.item = new Item()
    this.filter = new Filter()
    this.file = new File()
    this.dispute = new Dispute()
    this.requirements = new Requirements()
    this.homologation = new Homologation()
    this.history = new History()
  }

  fetchBiddings(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }

      if (parameters.params.date_start) {
        parameters.params.date__gte = parameters.params.date_start
      }

      if (parameters.params.date_finish) {
        parameters.params.date__lte = parameters.params.date_finish
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch biddings')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch biddings')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchBiddingList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }

      if (parameters.params.date_start) {
        parameters.params.date__gte = parameters.params.date_start
      }

      if (parameters.params.date_finish) {
        parameters.params.date__lte = parameters.params.date_finish
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch biddings')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch biddings')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchBiddingListContract(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }

      if (parameters.params.date_start) {
        parameters.params.date__gte = parameters.params.date_start
      }

      if (parameters.params.date_finish) {
        parameters.params.date__lte = parameters.params.date_finish
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/list/contract/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch biddings')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch biddings')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchBidding(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateBidding(id, biddingData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/${id}/`, biddingData)
        .then(response => {
          log.debug('[DEBUG] Success to update bidding')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update bidding')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addBidding(biddingData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/', biddingData)
        .then(response => {
          log.debug('[DEBUG] Success to add bidding')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add bidding')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteBidding(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/bidding/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete bidding')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete bidding')
          log.error(error)
          reject(error.response)
        })
    })
  }

  toFileBidding(id, biddingData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/filed/${id}/`, biddingData)
        .then(() => {
          log.debug('[DEBUG] Success to file bidding')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to file bidding')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding history')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
