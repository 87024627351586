import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

import syslic from '@/syslic'

export default [
  {
    path: '/order/:id/commitment/create',
    name: 'order-commitment-create',
    component: () => import('@/views/order/components/OrderCommitmentEdit.vue'),
    meta: {
      pageTitle: 'Empenho',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Empenho',
          toName: 'order-detail',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'create',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
  {
    path: '/order/:id/commitment/view/:idcommitment',
    name: 'order-commitment-detail',
    component: () => import('@/views/order/components/OrderCommitmentEdit.vue'),
    meta: {
      pageTitle: 'Empenho',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Empenho',
          toName: 'order-detail',
        },
        {
          prefix: '#',
          routerParam: 'idcommitment',
          toName: 'order-commitment-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
      beforeEnter: (to, from, next) => {
        const canEdit = to.meta.permissions.can_edit

        if (canEdit) {
          const commitmentId = to.params.idcommitment
          const goBackPath = to.params.goBackTo ? to.params.goBackTo : 'commitment'

          syslic
            .order
            .commitment
            .fetchCommitment(commitmentId)
            .then(response => {
              const commitment = response.data

              if (commitment.situation === 'audit' || commitment.situation === 'done') {
                next()
              } else {
                next({
                  name: 'order-commitment-edit',
                  params: {
                    id: to.params.id,
                    idcommitment: commitmentId,
                    goBackTo: goBackPath,
                  },
                })
              }
            })
            .catch(() => {
              next({ name: 'commitment' })
            })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/order/:id/commitment/edit/:idcommitment',
    name: 'order-commitment-edit',
    component: () => import('@/views/order/components/OrderCommitmentEdit.vue'),
    meta: {
      pageTitle: 'Empenho',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'order-detail',
        },
        {
          text: 'Empenho',
          toName: 'order-detail',
        },
        {
          prefix: '#',
          routerParam: 'idcommitment',
          toName: 'order-commitment-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'update',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
