import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Requirements {
  constructor() {
    log.info('[INFO] Initialize Requirements Module')
    this.cache = new Cache()
  }

  fetchAllRequirements(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/requirement/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch requirements')
            resolve(response.data)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch requirements')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchRequirements(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/requirement/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch requirements')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch requirements')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateRequirements(id, requirementData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/requirement/${id}/`, requirementData)
        .then(response => {
          log.debug('[DEBUG] Success to update requirements')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update requirements')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addRequirements(requirementData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/requirement/', requirementData)
        .then(response => {
          log.debug('[DEBUG] Success to add requirements')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add requirements')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteRequirements(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/bidding/requirement/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete requirements')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete requirements')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
