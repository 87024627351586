import log from 'loglevel'
import axios from 'axios'
import Cache from '../../utils/cache'

import Status from './status'
import Type from './type'
import Filter from './filter'

/* eslint class-methods-use-this: ["off"] */

export default class Assistance {
  constructor() {
    log.info('[INFO] Initialize Assistance Module')
    this.cache = new Cache()

    this.status = new Status()
    this.type = new Type()
    this.filter = new Filter()
  }

  fetchAllAssistances(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/assistance/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch assistances')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch assistances')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchAssistanceList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/assistance-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch assistance list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch assistance list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchAssistance(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/assistance/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch assistance')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch assistance')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchAssistanceBasic(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/assistance-basic/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch assistance basic')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch assistance basic')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateAssistance(id, assistenceData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/assistance/${id}/`, assistenceData)
        .then(response => {
          log.debug('[DEBUG] Success to update assistance')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update assistance')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateAssistanceBasic(id, assistenceData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/assistance-basic/${id}/`, assistenceData)
        .then(response => {
          log.debug('[DEBUG] Success to update assistance basic')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update assistance basic')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addAssistance(assistenceData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/assistance/', assistenceData)
        .then(response => {
          log.debug('[DEBUG] Success to add assistance')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add assistance')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteAssistance(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/assistance/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete assistance')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete assistance')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchOrder(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/assistance-order/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch order to assistance')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch order to assistance')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
