export default [
  {
    path: '/settings/contract/status',
    name: 'contract-status',
    component: () => import('@/views/contract/settings/Status.vue'),
    meta: {
      pageTitle: 'Status do Contrato',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'contract-status',
        },
        {
          text: 'Contrato',
          toName: 'contract-status',
        },
        {
          text: 'Status do Contrato',
          toName: 'contract-status',
        },
      ],
      requireLogin: true,
      resource: 'ContractSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
