import log from 'loglevel'
import axios from 'axios'
/* eslint class-methods-use-this: ["off"] */

export default class ItemList {
  constructor() {
    log.info('[INFO] Initialize Item List Module')
  }

  fetchLists(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/${id}/material-list/`,
          {
            params: {
              p: params.page,
              page_size: params.page_size,
              search: params.q,
            },
          })
        .then(response => {
          log.debug('[DEBUG] Success to fetch material list')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch materail list')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchList(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/material-list/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch list')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch list')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addList(listData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/product/material-list/', listData)
        .then(response => {
          log.debug('[DEBUG] Success to add list')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add list')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateList(id, listData) {
    log.debug('[DEBUG] id and data', id, listData)
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/product/material-list/${id}/`, listData)
        .then(response => {
          log.debug('[DEBUG] Success to update list')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update list')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteList(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/product/material-list/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete list with id: ${id}`)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete list')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/product/material-list/item/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete list item with id: ${id}`)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete list item')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
