import {
  mdiClipboardEditOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

export default [
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/company-list/CompaniesList.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'company',
        },
        {
          text: 'Empresas',
          toName: 'company',
        },
      ],
      requireLogin: true,
      resource: 'Company',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/company/view/:id',
    name: 'company-detail',
    component: () => import('@/views/company/company-edit/CompanyEdit.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'company',
        },
        {
          text: 'Empresas',
          toName: 'company',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'company-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Company',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'company-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
  {
    path: '/company/create/',
    name: 'company-create',
    component: () => import('@/views/company/company-edit/CompanyEdit.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'company',
        },
        {
          text: 'Empresas',
          toName: 'company',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Company',
      action: 'create',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/company/edit/:id',
    name: 'company-edit',
    component: () => import('@/views/company/company-edit/CompanyEdit.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Empresas',
          toName: 'company',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'company-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Company',
      action: 'update',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/company/document/:id',
    name: 'company-documents',
    component: () => import('@/views/company/company-documents/CompanyDocuments.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'company',
        },
        {
          text: 'Empresas',
          toName: 'company',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'company-detail',
        },
        {
          text: 'Documentos',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Company',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/company/certificate/:id',
    name: 'company-certificates',
    component: () => import('@/views/company/company-certificates/CompanyCertificates.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'company',
        },
        {
          text: 'Empresas',
          toName: 'company',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'company-detail',
        },
        {
          text: 'Atestados',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Company',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
]
