import log from 'loglevel'
import axios from 'axios'

import Cache from '@/syslic/utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class DeliveryItem {
  constructor() {
    log.info('[INFO] Initialize Delivery Item Module')
    this.cache = new Cache()
  }

  fetchAllDeliveryItem(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/delivery/item/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch delivery item')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch deliverys')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchDeliveryItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/delivery/item/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch delivery item')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch delivery item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateDeliveryItem(id, deliveryData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/delivery/item/${id}/`, deliveryData)
        .then(response => {
          log.debug('[DEBUG] Success to update delivery item')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update delivery item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addDeliveryItem(deliveryData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/delivery/item/', deliveryData)
        .then(response => {
          log.debug('[DEBUG] Success to add delivery item')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add delivery item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteDeliveryItem(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/delivery/item/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete delivery item')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete delivery item')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchItemsToDelivery(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/items/to/delivery/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch items to delivery')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch items to delivery')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateDeliveryItemProduct(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/delivery/item/product/${id}/`, data)
        .then(response => {
          log.debug('[DEBUG] Success to update delivery item product')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update delivery item product')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
