import log from 'loglevel'
import Item from './item'
import Classifier from './classifiers'
import Unity from './unity'
import Type from './type'
import Warranty from './warranty'
import Image from './image'
import File from './file'
import ImportProduct from './importProduct'

/* eslint class-methods-use-this: ["off"] */

export default class Product {
  constructor() {
    log.info('[INFO] Initialize Product Module')
    this.item = new Item()
    this.image = new Image()
    this.classifier = new Classifier()
    this.unit = new Unity()
    this.type = new Type()
    this.warranty = new Warranty()
    this.file = new File()
    this.importProduct = new ImportProduct()
  }
}
