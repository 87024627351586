import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Spheres {
  constructor() {
    log.info('[INFO] Initialize Spheres Module')
  }

  fetchSpheres(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/pncp/spheres/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch spheres')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch spheres')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchSphere(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/pncp/spheres/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch sphere')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch sphere')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateSphere(id, sphereData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/pncp/spheres/${id}/`, sphereData)
        .then(response => {
          log.debug('[DEBUG] Success to update sphere')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update sphere')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addSphere(sphereData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/pncp/spheres/', sphereData)
        .then(response => {
          log.debug('[DEBUG] Success to add sphere')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add sphere')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteSphere(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/pncp/spheres/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete sphere')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete sphere')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
