export default [
  {
    path: '/permission',
    name: 'permission',
    component: () => import('@/views/permission/permission-list/PermissionList.vue'),
    meta: {
      pageTitle: 'Permissões',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'permission',
        },
        {
          text: 'Acessos',
          toName: 'permission',
        },
        {
          text: 'Permissões',
          toName: 'permission',
        },
      ],
      requireLogin: true,
      resource: 'Permission',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/permission/create',
    name: 'permission-create',
    component: () => import('@/views/permission/permission-edit/PermissionEditView.vue'),
    meta: {
      pageTitle: 'Permissões',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'permission',
        },
        {
          text: 'Acessos',
          toName: 'permission',
        },
        {
          text: 'Permissões',
          toName: 'permission',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Permission',
      action: 'create',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/permission/edit/:id',
    name: 'permission-edit',
    component: () => import('@/views/permission/permission-edit/PermissionEditView.vue'),
    meta: {
      pageTitle: 'Permissões',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'permission',
        },
        {
          text: 'Acessos',
          toName: 'permission',
        },
        {
          text: 'Permissões',
          toName: 'permission',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'permission-view',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Permission',
      action: 'update',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/permission/view/:id',
    name: 'permission-view',
    component: () => import('@/views/permission/permission-edit/PermissionEditView.vue'),
    meta: {
      pageTitle: 'Permissões',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'permission',
        },
        {
          text: 'Acessos',
          toName: 'permission',
        },
        {
          text: 'Permissões',
          toName: 'permission',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'permission-view',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Permission',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
