export default [
  {
    path: '/settings/product/classifier',
    name: 'product-classifier',
    component: () => import('@/views/product/settings/Classifier.vue'),
    meta: {
      pageTitle: 'Categorias',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'product-classifier',
        },
        {
          text: 'Produtos',
          toName: 'product-classifier',
        },
        {
          text: 'Categorias',
          toName: 'product-classifier',
        },
      ],
      requireLogin: true,
      resource: 'ProductSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/product/type',
    name: 'product-type',
    component: () => import('@/views/product/settings/Type.vue'),
    meta: {
      pageTitle: 'Tipos',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'product-type',
        },
        {
          text: 'Produtos',
          toName: 'product-type',
        },
        {
          text: 'Tipos',
          toName: 'product-type',
        },
      ],
      requireLogin: true,
      resource: 'ProductSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/product/unity',
    name: 'product-unity',
    component: () => import('@/views/product/settings/Unity.vue'),
    meta: {
      pageTitle: 'Unidades',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'product-unity',
        },
        {
          text: 'Produtos',
          toName: 'product-unity',
        },
        {
          text: 'Unidades',
          toName: 'product-unity',
        },
      ],
      requireLogin: true,
      resource: 'ProductSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/product/warranty',
    name: 'product-warranty',
    component: () => import('@/views/product/settings/Warranty.vue'),
    meta: {
      pageTitle: 'Garantias',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'product-warranty',
        },
        {
          text: 'Produtos',
          toName: 'product-warranty',
        },
        {
          text: 'Garantias',
          toName: 'product-warranty',
        },
      ],
      requireLogin: true,
      resource: 'ProductSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
