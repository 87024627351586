import log from 'loglevel'
import axios from 'axios'

import DeliveryItem from './item'
import DeliveryFilter from './filter'
import DeliveryStatus from './status'
import DeliveryFreight from './freight'
import DeliveryFile from './file'
import History from './history'
import Situation from './situation'

import Cache from '@/syslic/utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Delivery {
  constructor() {
    log.info('[INFO] Initialize Commitment Module')
    this.cache = new Cache()

    this.filter = new DeliveryFilter()
    this.status = new DeliveryStatus()
    this.item = new DeliveryItem()
    this.freight = new DeliveryFreight()
    this.file = new DeliveryFile()
    this.history = new History()
    this.situation = new Situation()
  }

  fetchDeliveryList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/delivery/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch delivery list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch delivery list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchDeliveries(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/delivery/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch deliveries')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch deliveries')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchDelivery(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/delivery/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch delivery')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch delivery')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateDelivery(id, deliveryData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/delivery/${id}/`, deliveryData)
        .then(response => {
          log.debug('[DEBUG] Success to update delivery')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update delivery')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addDelivery(deliveryData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/delivery/', deliveryData)
        .then(response => {
          log.debug('[DEBUG] Success to add delivery')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add delivery')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteDelivery(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/delivery/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete delivery')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete delivery')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/delivery/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch delivery history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch delivery history')
          log.error(error)
          reject(error.response)
        })
    })
  }

  hasInvoicing(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/delivery/has-invoicing/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to has invoicing')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to has invoicing')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
