import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class History {
  constructor() {
    log.info('[INFO] Initialize History Module')
  }

  fetchAllHistory(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/order/delivery/history/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch history')
          resolve(response.data)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch history')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/delivery/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch history')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
