import {
  mdiClipboardEditOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

export default [
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/product-list/ItemsList.vue'),
    meta: {
      pageTitle: 'Produtos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'product',
        },
        {
          text: 'Produtos',
          toName: 'product',
        },
      ],
      requireLogin: true,
      resource: 'Product',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/product/create',
    name: 'product-item-create',
    component: () => import('@/views/product/product-edit/ItemEditView.vue'),
    meta: {
      pageTitle: 'Produtos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'product',
        },
        {
          text: 'Produtos',
          toName: 'product',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Product',
      action: 'create',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/product/edit/:id',
    name: 'product-item-edit',
    component: () => import('@/views/product/product-edit/ItemEditView.vue'),
    meta: {
      pageTitle: 'Produtos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'product',
        },
        {
          text: 'Produtos',
          toName: 'product',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'product-item-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Product',
      action: 'update',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/product/view/:id',
    name: 'product-item-detail',
    component: () => import('@/views/product/product-edit/ItemEditView.vue'),
    meta: {
      pageTitle: 'Produtos',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'product',
        },
        {
          text: 'Produtos',
          toName: 'product',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'product-item-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Product',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'product-item-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
]
