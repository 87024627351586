import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class MetaData {
  constructor() {
    log.info('[INFO] Initialize MetaData Module')
  }

  fetchMetaData(profile) {
    const parameters = {
      params: {
        profile,
      },
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/metadata/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch metadata')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch metadata')
          log.error(error)
          reject(error.response)
        })
    })
  }

  setMetadata(profile, tag, meta) {
    const data = {
      profile,
      tag,
      meta,
    }

    return new Promise((resolve, reject) => {
      axios
        .post('v1/metadata/', data)
        .then(response => {
          log.debug('[DEBUG] Success to add metadata')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add metadata')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
