import {
  mdiClipboardTextMultipleOutline,
} from '@mdi/js'

export default [
  {
    path: '/assistance',
    name: 'assistance',
    component: () => import('@/views/order/assistance/AssistanceList.vue'),
    meta: {
      pageTitle: 'Assistências',
      breadcrumb: [
        {
          text: 'Pedidos',
          toName: 'order',
        },
        {
          text: 'Todas as Assistências',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Order',
      action: 'read',
      iconSvg: mdiClipboardTextMultipleOutline,
    },
  },
]
