import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Status {
  constructor() {
    log.info('[INFO] Initialize Status Module')
    this.cache = new Cache()
  }

  fetchAllStatus(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/contract/status/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch all status')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch all status')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/contract/status/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateStatus(id, statusData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/contract/status/${id}/`, statusData)
        .then(response => {
          log.debug('[DEBUG] Success to update status')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addStatus(statusData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/contract/status/', statusData)
        .then(response => {
          log.debug('[DEBUG] Success to add status')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/contract/status/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete status')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete status')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
