import log from 'loglevel'
import axios from 'axios'

export default class Authentication {
  constructor() {
    this.token = ''
    this.keepConnected = false
    this.profile = null
    this.profilePhoto = ''
    this.notifyProfileUpdate = () => {}

    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token')
      this.keepConnected = true
    } else if (sessionStorage.getItem('token')) {
      this.token = sessionStorage.getItem('token')
      this.keepConnected = true
    }

    this.profile = JSON.parse(localStorage.getItem('userProfileData'))
    this.profilePhoto = localStorage.getItem('userProfilePhotoData')

    if (this.token) {
      axios.defaults.headers.common.Authorization = `Token ${this.token}`
    } else {
      axios.defaults.headers.common.Authorization = ''
    }

    log.info('[INFO] Initialize Authentication Module')
  }

  isAuthenticated() {
    if (this.token !== '') {
      return true
    }
    return false
  }

  setProfile(data) {
    this.profile = data
    localStorage.setItem('userProfileData', JSON.stringify(this.profile))
  }

  removeProfile() {
    this.profile = null
    localStorage.removeItem('userProfileData')
  }

  getProfile() {
    return this.profile
  }

  setProfilePhoto(data) {
    this.profilePhoto = `data:image/jpg;base64,${data}`
    localStorage.setItem('userProfilePhotoData', this.profilePhoto)
  }

  removeProfilePhoto() {
    this.profilePhoto = ''
    localStorage.removeItem('userProfilePhotoData')
  }

  getProfilePhoto() {
    return this.profilePhoto
  }

  bindNotificationProfileUpdate(callback) {
    this.notifyProfileUpdate = callback
  }

  loadProfile() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get('/v1/users/me/')
        .then(response => {
          const id = response.data.uuid
          return axios.get(`/v1/accounts/user-profile/${id}/`)
        })
        .then(response => {
          const id = response.data.get_uuid
          this.setProfile(response.data)
          return axios.get(`/v1/accounts/user/photo/${id}/`)
        })
        .then(response => {
          this.setProfilePhoto(response.data.image)
          this.notifyProfileUpdate()
          return this.loadMetaData()
        })
        .then(() => {
          log.debug('[DEBUG] Success to load profile')
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to load profile')
          log.error(err)
          reject(err.response)
        })
    })
    return promise
  }

  loadMetaData() {
    const parameters = {
      params: {
        profile: this.profile.id,
      },
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/metadata/', parameters)
        .then(response => {
          Object.keys(localStorage).forEach(key => {
            const regex = new RegExp('metaData-.*')
            if (regex.test(key)) {
              localStorage.removeItem(key)
            }
          })

          response.data.results.forEach(obj => {
            const meta = JSON.stringify(obj.meta)
            localStorage.setItem(obj.tag, meta)
          })

          log.debug('[DEBUG] Success to fetch metadata')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch metadata')
          log.error(error)
          reject(error.response)
        })
    })
  }

  removeToken() {
    log.debug('[DEBUG] Clear authentication token')
    axios.defaults.headers.common.Authorization = ''
    this.token = ''
    this.keepConnected = false
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  }

  setToken(token, isPersistant) {
    log.debug('[DEBUG] Register authentication token:', token)
    this.token = token
    this.keepConnected = isPersistant

    if (token) {
      axios.defaults.headers.common.Authorization = `Token ${token}`
    } else {
      axios.defaults.headers.common.Authorization = ''
    }

    if (isPersistant) {
      localStorage.setItem('token', token)
    } else {
      sessionStorage.setItem('token', token)
    }
  }

  login(email, password, isPersistent) {
    const data = { email, password }

    const promise = new Promise((resolve, reject) => {
      log.debug('[DEBUG] Starting login')
      axios
        .post('/v1/token/login/', data)
        .then(response => {
          this.setToken(response.data.auth_token, isPersistent)
          return this.loadProfile()
        })
        .then(() => {
          log.debug('[DEBUG] Success to login in email:', email)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to login with email:', email)
          log.error(err)
          reject(err)
        })
    })
    return promise
  }

  logoff() {
    log.debug('[DEBUG] Success to logoff')
    this.removeToken()
    localStorage.clear()
    sessionStorage.clear()
  }
}
