import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class ImportProduct {
  constructor() {
    log.info('[INFO] Initialize Import Product SubModule')
  }

  verifyImportProductByFile(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/product/validate/file/', data)
        .then(response => {
          log.debug('[DEBUG] Success to verify import product file')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to verify import product by file')
          log.error(err)
          reject(err.response)
        })
    })
  }

  importProductByFile(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/product/import/file/', data)
        .then(response => {
          log.debug('[DEBUG] Success to import product file')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to import product by file')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
