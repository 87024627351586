export default [
  {
    path: '/settings/transport/freight',
    name: 'transport-freight',
    component: () => import('@/views/transport/Freight.vue'),
    meta: {
      pageTitle: 'Estimativa de Frete',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'transport-freight',
        },
        {
          text: 'Transporte',
          toName: 'transport-freight',
        },
        {
          text: 'Estimativa de Frete',
          toName: 'transport-freight',
        },
      ],
      requireLogin: true,
      resource: 'TransportSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
