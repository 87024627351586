import { mdiGavel } from '@mdi/js'

import settings from './settings'
import syslic from '@/syslic'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/bidding/BiddingList.vue'),
    meta: {
      pageTitle: 'Minhas Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          text: 'Minhas Licitações',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'read',
      iconSvg: mdiGavel,
    },
  },
  {
    path: '/bidding',
    name: 'bidding',
    component: () => import('@/views/bidding/BiddingList.vue'),
    meta: {
      pageTitle: 'Minhas Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          text: 'Minhas Licitações',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'read',
      iconSvg: mdiGavel,
    },
  },
  {
    path: '/bidding/create',
    name: 'bidding-create',
    component: () => import('@/views/bidding/BiddingEdit.vue'),
    meta: {
      pageTitle: 'Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'create',
      iconSvg: mdiGavel,
    },
  },
  {
    path: '/bidding/edit/:id',
    name: 'bidding-edit',
    component: () => import('@/views/bidding/BiddingEdit.vue'),
    meta: {
      pageTitle: 'Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'bidding-view',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'update',
      iconSvg: mdiGavel,
    },
  },
  {
    path: '/bidding/view/:id',
    name: 'bidding-view',
    component: () => import('@/views/bidding/BiddingEdit.vue'),
    meta: {
      pageTitle: 'Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'bidding-view',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'read',
      iconSvg: mdiGavel,
      beforeEnter: (to, from, next) => {
        const canEdit = to.meta.permissions.can_edit

        if (canEdit) {
          const biddingId = to.params.id

          syslic
            .bidding
            .fetchBidding(biddingId)
            .then(response => {
              const bidding = response.data

              if (bidding.is_homologated) {
                next()
              } else {
                next({ name: 'bidding-edit', params: { id: biddingId } })
              }
            })
            .catch(() => {
              next({ name: 'bidding' })
            })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/bidding/search/:cnpj/:year/:seq',
    name: 'bidding-search-view',
    component: () => import('@/views/pncp/PncpBiddingView.vue'),
    meta: {
      pageTitle: 'Buscar Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          text: 'Buscar Licitações',
          toName: 'bidding-search',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'update',
      iconSvg: mdiGavel,
    },
  },
  {
    path: '/bidding/search',
    name: 'bidding-search',
    component: () => import('@/views/pncp/PncpBiddingList.vue'),
    meta: {
      pageTitle: 'Buscar Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding-search',
        },
        {
          text: 'Buscar Licitações',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'read',
      iconSvg: mdiGavel,
    },
  },
  {
    path: '/bidding/classification-grid/:id',
    name: 'bidding-classification-grid',
    component: () => import('@/views/bidding/classification-grid/BiddingClassificationGrid.vue'),
    meta: {
      pageTitle: 'Licitações',
      breadcrumb: [
        {
          text: 'Licitações',
          toName: 'bidding',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'bidding-edit',
        },
        {
          text: 'Grade Classificatória',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Bidding',
      action: 'update',
      iconSvg: mdiGavel,
    },
  },
  ...settings,
]
