import {
  mdiCash,
} from '@mdi/js'

export default [
  {
    path: '/commission',
    name: 'commission',
    component: () => import('@/views/commission/CommissionList.vue'),
    meta: {
      pageTitle: 'Comissões',
      breadcrumb: [
        {
          text: 'Comissões',
          toName: 'commission',
        },
      ],
      requireLogin: true,
      resource: 'Auth',
      action: 'read',
      iconSvg: mdiCash,
    },
  },
  {
    path: '/commission/view/:id',
    name: 'commission-detail',
    component: () => import('@/views/commission/CommissionEdit.vue'),
    meta: {
      pageTitle: 'Comissões',
      breadcrumb: [
        {
          text: 'Comissões',
          toName: 'commission',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Auth',
      action: 'read',
      iconSvg: mdiCash,
    },
  },
  {
    path: '/commission/edit/:id',
    name: 'commission-edit',
    component: () => import('@/views/commission/CommissionEdit.vue'),
    meta: {
      pageTitle: 'Comissões',
      breadcrumb: [
        {
          text: 'Comissões',
          toName: 'commission',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Commission',
      action: 'update',
      iconSvg: mdiCash,
    },
  },
]
