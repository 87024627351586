import {
  mdiClipboardEditOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

import settings from './settings'

export default [
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/views/supplier/SupplierList.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'supplier',
        },
        {
          text: 'Fornecedores',
          toName: 'supplier',
        },
      ],
      requireLogin: true,
      resource: 'Supplier',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/supplier/view/:id',
    name: 'supplier-detail',
    component: () => import('@/views/supplier/SupplierEdit.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'supplier',
        },
        {
          text: 'Fornecedores',
          toName: 'supplier',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'supplier-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Supplier',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'supplier-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
  {
    path: '/supplier/create/',
    name: 'supplier-create',
    component: () => import('@/views/supplier/SupplierEdit.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'supplier',
        },
        {
          text: 'Fornecedores',
          toName: 'supplier',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Supplier',
      action: 'create',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/supplier/edit/:id',
    name: 'supplier-edit',
    component: () => import('@/views/supplier/SupplierEdit.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'supplier',
        },
        {
          text: 'Fornecedores',
          toName: 'supplier',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'supplier-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Supplier',
      action: 'update',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  ...settings,
]
