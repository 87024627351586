import {
  mdiClipboardEditOutline,
} from '@mdi/js'

import viewOrEdit from '@/router/helper'

import freight from './freight'

export default [
  {
    path: '/transport',
    name: 'transport',
    component: () => import('@/views/transport/TransportList.vue'),
    meta: {
      pageTitle: 'Transportadoras',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'transport',
        },
        {
          text: 'Transportadoras',
          toName: 'transport',
        },
      ],
      requireLogin: true,
      resource: 'Transport',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/transport/view/:id',
    name: 'transport-detail',
    component: () => import('@/views/transport/TransportEdit.vue'),
    meta: {
      pageTitle: 'Transportadoras',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'transport',
        },
        {
          text: 'Transportadoras',
          toName: 'transport',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'transport-detail',
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Transport',
      action: 'read',
      iconSvg: mdiClipboardEditOutline,
      beforeEnter: (to, from, next) => {
        viewOrEdit(to, next, {
          name: 'transport-edit',
          params: {
            id: to.params.id,
          },
        })
      },
    },
  },
  {
    path: '/transport/create/',
    name: 'transport-create',
    component: () => import('@/views/transport/TransportEdit.vue'),
    meta: {
      pageTitle: 'Transportadoras',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'transport',
        },
        {
          text: 'Transportadoras',
          toName: 'transport',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Transport',
      action: 'create',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  {
    path: '/transport/edit/:id',
    name: 'transport-edit',
    component: () => import('@/views/transport/TransportEdit.vue'),
    meta: {
      pageTitle: 'Transportadoras',
      breadcrumb: [
        {
          text: 'Cadastros',
          toName: 'transport',
        },
        {
          text: 'Transportadoras',
          toName: 'transport',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'transport-detail',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Transport',
      action: 'update',
      iconSvg: mdiClipboardEditOutline,
    },
  },
  ...freight,
]
