import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Report {
  constructor() {
    log.info('[INFO] Initialize Report Module')
  }

  fetchReports(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/report/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch reports')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch reports')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
