import log from 'loglevel'
import axios from 'axios'
import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Unity {
  constructor() {
    log.info('[INFO] Initialize Unity SubModule')
    this.cache = new Cache()
  }

  fetchUnits(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/product/unity/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch unit')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch unit')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchUnit(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/unity/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch unit')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch unit')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateUnit(id, unitData) {
    return new Promise((resolve, reject) => {
      log.debug('[DEBUG] Unit Data:', unitData)
      log.debug('[DEBUG] Unit ID:', id)
      axios
        .put(`v1/product/unity/${id}/`, unitData)
        .then(response => {
          log.debug('[DEBUG] Success to update unit')
          this.cache.reset()
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update unit')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addUnit(unitData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/product/unity/', unitData)
        .then(response => {
          log.debug('[DEBUG] Success to add unit')
          this.cache.reset()
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add unit')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteUnit(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/product/unity/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete unit with id: ${id}`)
          this.cache.reset()
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete unit')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
