import log from 'loglevel'
import axios from 'axios'
import Cache from '../../utils/cache'

import InvoicingStatus from './status'
import InvoicingFile from './file'
import InvoicingFilter from './filter'

/* eslint class-methods-use-this: ["off"] */

export default class Invoicing {
  constructor() {
    log.info('[INFO] Initialize Invoicing Module')
    this.cache = new Cache()

    this.status = new InvoicingStatus()
    this.file = new InvoicingFile()
    this.filter = new InvoicingFilter()
  }

  fetchInvoicings(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/invoicing/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch invoicing list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch invoicing list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchInvoicingList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/invoicing-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch invoicing list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch invoicing list')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchInvoicing(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/invoicing-view/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch invoicing')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch invoicing')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateInvoicing(id, invoicingData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/invoicing/${id}/`, invoicingData)
        .then(response => {
          log.debug('[DEBUG] Success to update invoicing')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update invoicing')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addInvoicing(invoicingData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/invoicing/create/', invoicingData)
        .then(response => {
          log.debug('[DEBUG] Success to add invoicing')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add invoicing')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteInvoicing(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/invoicing/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete invoicing')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete invoicing')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateSituation(id, value) {
    const invoicingSituation = {
      situation: value,
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/invoicing/situation/${id}/`, invoicingSituation)
        .then(response => {
          log.debug('[DEBUG] Success to update invoicing situation')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update invoicing situation')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchInvoicingItems(id, params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/invoicing/items/${id}/`, parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch invoicing items')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch invoicing items')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchHistory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/invoicing/history/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch invoicing history')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch invoicing history')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
