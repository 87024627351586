import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class MessagerVisualization {
  constructor() {
    log.info('[INFO] Initialize Messager Visualization Module')
  }

  notifyVisualization(message, viewer) {
    const data = {
      viewer,
      message,
    }
    return new Promise((resolve, reject) => {
      axios
        .post('v1/messager/visualization/', data)
        .then(response => {
          log.debug('[DEBUG] Success to notify visualization')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to notify visualization')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
