export default [
  {
    path: '/settings/supplier/category',
    name: 'supplier-category',
    component: () => import('@/views/supplier/settings/Category.vue'),
    meta: {
      pageTitle: 'Categorias',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'supplier-category',
        },
        {
          text: 'Fornecedores',
          toName: 'supplier-category',
        },
        {
          text: 'Categoria',
          toName: 'supplier-category',
        },
      ],
      requireLogin: true,
      resource: 'SupplierSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
