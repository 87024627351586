import log from 'loglevel'
import axios from 'axios'
/* eslint class-methods-use-this: ["off"] */

export default class CompanyFile {
  constructor() {
    log.info('[INFO] Initialize Company Document Detail SubModule')
  }

  fetchAllFilesDetail(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/company/attach/file/detail/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch company file detail')
          resolve(response.data)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch company file detail')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateCompanyFileDetail(id, companyFileData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/company/attach/file/detail/${id}/`, companyFileData)
        .then(response => {
          log.debug('[DEBUG] Success to update company file')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update company file')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addCompanyFileDetail(companyFileData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/company/attach/file/detail/', companyFileData)
        .then(response => {
          log.debug('[DEBUG] Success to add company file')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add company file')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteCompanyFileDetail(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/company/attach/file/detail/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete company file with id:${id}`)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete company file')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
