import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Category {
  constructor() {
    log.info('[INFO] Initialize Category Module')
    this.cache = new Cache()
  }

  fetchCategories(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/supplier/category/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch categories')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch categories')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchCategory(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/supplier/category/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch category')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch category')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateCategory(id, categoryData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/supplier/category/${id}/`, categoryData)
        .then(response => {
          log.debug('[DEBUG] Success to update category')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update category')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addCategory(categoryData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/supplier/category/', categoryData)
        .then(response => {
          log.debug('[DEBUG] Success to add category')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add category')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteCategory(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/supplier/category/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete category')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete category')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
