import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Situation {
  constructor() {
    log.info('[INFO] Initialize Situation Module')
  }

  updateSituation(id, situation) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/delivery/situation/${id}/`, situation)
        .then(response => {
          log.debug('[DEBUG] Success to update delivery situation')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update delivery situation')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
