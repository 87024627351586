export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      blockedAfterLogin: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/authentication/Logout.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      resource: 'Auth',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
