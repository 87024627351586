import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class TypeCallingInstrument {
  constructor() {
    log.info('[INFO] Initialize TypeCallingInstrument Module')
  }

  fetchTypeCallingInstruments(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('v1/pncp/type-calling-instrument/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to fetch type calling instruments')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch type calling instruments')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchTypeCallingInstrument(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/pncp/type-calling-instrument/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch type calling instrument')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch type calling instrument')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateTypeCallingInstrument(id, typeCallingInstrumentData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/pncp/type-calling-instrument/${id}/`, typeCallingInstrumentData)
        .then(response => {
          log.debug('[DEBUG] Success to update type calling instrument')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update type calling instrument')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addTypeCallingInstrument(typeCallingInstrumentData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/pncp/type-calling-instrument/', typeCallingInstrumentData)
        .then(response => {
          log.debug('[DEBUG] Success to add type calling instrument')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add type calling instrument')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteTypeCallingInstrument(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/pncp/type-calling-instrument/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete type calling instrument')
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete type calling instrument')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
