import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */
/* eslint object-shorthand: ["off"] */

const GURU_URL = process.env.VUE_APP_GURU_ADDRESS || 'http://localhost:8080/lab/syslic/guru/'

export default class Guru {
  constructor() {
    log.info('[INFO] Initialize Guru Module')

    const GURU_API_ADDRESS = `${GURU_URL}/api/v1/`

    this.axios = axios.create({
      baseURL: GURU_API_ADDRESS,
      timeout: 540000,
    })

    this.axios.defaults.headers.common.Authorization = ''
  }

  fetchBiddingGrid(platform, biddingData, forceRefresh) {
    const platformLabel = platform.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

    const data = JSON.parse(JSON.stringify(biddingData))

    if (forceRefresh) {
      data.refresh = true
    }

    return new Promise((resolve, reject) => {
      this.axios.post(`platform/${platformLabel}/bidding/info`, data)
        .then(response => {
          log.debug('[DEBUG] Success to fetch bidding grid')
          resolve(response.data)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch bidding grid')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
