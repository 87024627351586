export default [
  {
    path: '/settings/order/interest',
    name: 'order-interest',
    component: () => import('@/views/order/settings/Interest.vue'),
    meta: {
      pageTitle: 'Prioridade',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'order-interest',
        },
        {
          text: 'Pedidos',
          toName: 'order-interest',
        },
        {
          text: 'Prioridade',
          toName: 'order-interest',
        },
      ],
      requireLogin: true,
      resource: 'OrderSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/order/commitment/status',
    name: 'order-status-commitment',
    component: () => import('@/views/order/settings/StatusCommitment.vue'),
    meta: {
      pageTitle: 'Status do Empenho',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'order-status-commitment',
        },
        {
          text: 'Pedidos',
          toName: 'order-status-commitment',
        },
        {
          text: 'Status do Empenho',
          toName: 'order-status-commitment',
        },
      ],
      requireLogin: true,
      resource: 'OrderSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/order/delivery/status',
    name: 'order-status-delivery',
    component: () => import('@/views/order/settings/StatusDelivery.vue'),
    meta: {
      pageTitle: 'Status da Entrega',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'order-status-delivery',
        },
        {
          text: 'Pedidos',
          toName: 'order-status-delivery',
        },
        {
          text: 'Status da Entrega',
          toName: 'order-status-delivery',
        },
      ],
      requireLogin: true,
      resource: 'OrderSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/order/assistance/status',
    name: 'order-status-assistance',
    component: () => import('@/views/order/settings/StatusAssistance.vue'),
    meta: {
      pageTitle: 'Status da Assistência',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'order-status-assistance',
        },
        {
          text: 'Pedidos',
          toName: 'order-status-assistance',
        },
        {
          text: 'Status da Assistência',
          toName: 'order-status-assistance',
        },
      ],
      requireLogin: true,
      resource: 'OrderSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/order/audit',
    name: 'order-audit',
    component: () => import('@/views/order/settings/Audit.vue'),
    meta: {
      pageTitle: 'Auditoria',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'order-status-assistance',
        },
        {
          text: 'Pedidos',
          toName: 'order-status-assistance',
        },
        {
          text: 'Auditoria',
          toName: 'order-audit',
        },
      ],
      requireLogin: true,
      resource: 'OrderSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/order/invoicing/status',
    name: 'order-status-invoicing',
    component: () => import('@/views/order/settings/StatusInvoicing.vue'),
    meta: {
      pageTitle: 'Status do Faturamento',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'order-status-invoicing',
        },
        {
          text: 'Pedidos',
          toName: 'order-status-invoicing',
        },
        {
          text: 'Status do Faturamento',
          toName: 'order-status-invoicing',
        },
      ],
      requireLogin: true,
      resource: 'OrderSettings',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
]
