import log from 'loglevel'
import axios from 'axios'
/* eslint class-methods-use-this: ["off"] */

export default class CompanyFile {
  constructor() {
    log.info('[INFO] Initialize Company Document SubModule')
  }

  updateCompanyFile(id, companyFileData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/company/attach/file/${id}/`, companyFileData)
        .then(response => {
          log.debug('[DEBUG] Success to update company file')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update company file')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addCompanyFile(companyFileData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/company/attach/file/', companyFileData)
        .then(response => {
          log.debug('[DEBUG] Success to add company file')
          resolve(response.data)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add company file')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteCompanyFile(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/company/attach/file/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete company file with id:${id}`)
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete company file')
          log.error(err)
          reject(err.response)
        })
    })
  }

  fetchCompanyFileStatus() {
    return new Promise((resolve, reject) => {
      axios
        .get('v1/company/attach/status/')
        .then(response => {
          log.debug('[DEBUG] Success to fetch company file status')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch company file status')
          log.error(error)
          reject(error.response)
        })
    })
  }

  fetchFile(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/company/attach/file/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch file')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch file')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
