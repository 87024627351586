import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Platform {
  constructor() {
    log.info('[INFO] Initialize Platform Module')
    this.cache = new Cache()
  }

  fetchPlatforms(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/platform/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch platforms')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch platforms')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchPlatformList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/bidding/platform-list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch platform list')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch platform list')
            log.error(error)
            reject(error.response)
          })
      })
      return promise
    })
    return result
  }

  fetchPlatform(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/bidding/platform/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch platform')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch platform')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updatePlatform(id, platformData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/platform/${id}/`, platformData)
        .then(response => {
          log.debug('[DEBUG] Success to update platform')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update platform')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addPlatform(platformData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/platform/', platformData)
        .then(response => {
          log.debug('[DEBUG] Success to add platform')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add platform')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deletePlatform(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/bidding/platform/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete platform')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete platform')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addLogin(loginData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/bidding/platform/login/', loginData)
        .then(response => {
          log.debug('[DEBUG] Success to add login')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add login')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateLogin(id, loginData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/bidding/platform/login/${id}/`, loginData)
        .then(response => {
          log.debug('[DEBUG] Success to update login')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update login')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteLogin(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/bidding/platform/login/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete login')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete login')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
