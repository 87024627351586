export default [
  {
    path: '/settings/administrator/accounts',
    name: 'administrator-accounts',
    component: () => import('@/views/administrator/account/AccountList.vue'),
    meta: {
      pageTitle: 'Contas',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'administrator-accounts',
        },
        {
          text: 'Sistema',
          toName: 'administrator-accounts',
        },
        {
          text: 'Contas',
          toName: 'administrator-accounts',
        },
      ],
      requireLogin: true,
      resource: 'Admin',
      action: 'read',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/administrator/account/create',
    name: 'administrator-account-create',
    component: () => import('@/views/administrator/account/AccountEdit.vue'),
    meta: {
      pageTitle: 'Contas',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'administrator-accounts',
        },
        {
          text: 'Sistema',
          toName: 'administrator-accounts',
        },
        {
          text: 'Contas',
          toName: 'administrator-accounts',
        },
        {
          text: 'Adicionar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Admin',
      action: 'create',
      icon: 'SettingsIcon',
    },
  },
  {
    path: '/settings/administrator/account/edit/:id',
    name: 'administrator-account-edit',
    component: () => import('@/views/administrator/account/AccountEdit.vue'),
    meta: {
      pageTitle: 'Contas',
      breadcrumb: [
        {
          text: 'Configurações',
          toName: 'administrator-accounts',
        },
        {
          text: 'Sistema',
          toName: 'administrator-accounts',
        },
        {
          text: 'Contas',
          toName: 'administrator-accounts',
        },
        {
          prefix: '#',
          routerParam: 'id',
          toName: 'administrator-account-edit',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      requireLogin: true,
      resource: 'Admin',
      action: 'update',
      icon: 'SettingsIcon',
    },
  },
]
