import log from 'loglevel'
import axios from 'axios'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Interest {
  constructor() {
    log.info('[INFO] Initialize Interest Module')
    this.cache = new Cache()
  }

  fetchInterests(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/interest/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch interests')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch interests')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchInterest(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/interest/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch interest')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch interest')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateInterest(id, interestData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/interest/${id}/`, interestData)
        .then(response => {
          log.debug('[DEBUG] Success to update interest')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update interest')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addInterest(interestData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/interest/', interestData)
        .then(response => {
          log.debug('[DEBUG] Success to add interest')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add interest')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteInterest(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/interest/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete interest')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete interest')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
