import log from 'loglevel'
import axios from 'axios'
import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Type {
  constructor() {
    log.info('[INFO] Initialize Type SubModule')
    this.cache = new Cache()
  }

  fetchTypes(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/product/type/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch type')
            resolve(response.data)
          })
          .catch(err => {
            log.error('[ERROR] Fail to fetch type')
            log.error(err)
            reject(err.response)
          })
      })

      return promise
    })

    return result
  }

  fetchType(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/product/type/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch type')
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to fetch type')
          log.error(err)
          reject(err.response)
        })
    })
  }

  updateType(id, typeData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/product/type/${id}/`, typeData)
        .then(response => {
          log.debug('[DEBUG] Success to update type')
          this.cache.reset()
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to update type')
          log.error(err)
          reject(err.response)
        })
    })
  }

  addType(typeData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/product/type/', typeData)
        .then(response => {
          log.debug('[DEBUG] Success to add type')
          this.cache.reset()
          resolve(response)
        })
        .catch(err => {
          log.error('[ERROR] Fail to add type')
          log.error(err)
          reject(err.response)
        })
    })
  }

  deleteType(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/product/type/${id}/`)
        .then(() => {
          log.debug(`[DEBUG] Success to delete type with id: ${id}`)
          this.cache.reset()
          resolve()
        })
        .catch(err => {
          log.error('[ERROR] Fail to delete type')
          log.error(err)
          reject(err.response)
        })
    })
  }
}
