import log from 'loglevel'
import axios from 'axios'

/* eslint class-methods-use-this: ["off"] */

export default class Cities {
  constructor() {
    log.info('[INFO] Initialize Cities Module')

    this.axios = axios.create({
      baseURL: 'https://pncp.gov.br/api/',
      timeout: 10000,
    })

    this.axios.defaults.headers.common.Authorization = ''
  }

  fetchCities() {
    const parameters = {
      params: {
        pagina: 1,
        tam_pagina: 10,
        q: '',
        tipos_documento: 'edital',
        ordenacao: '-data',
        status: 'recebendo_proposta',
      },
    }

    return new Promise((resolve, reject) => {
      this.axios
        .get('search/', parameters)
        .then(response => {
          log.debug('[DEBUG] Success to cities from pncp')
          resolve(response.data.filters.municipios)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch cities from pncp')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
