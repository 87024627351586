import log from 'loglevel'
import axios from 'axios'

import Cache from '@/syslic/utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class DeliveryFilter {
  constructor() {
    log.info('[INFO] Initialize Filter Module')
    this.cache = new Cache()
  }

  fetchFilters(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params: {
          p: params.p,
          page_size: params.page_size,
          search: params.q,
        },
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/delivery/filter/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch filters')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch filters')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchFilter(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/delivery/filter/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch filter')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch filter')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateFilter(id, filterData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/delivery/filter/${id}/`, filterData)
        .then(response => {
          log.debug('[DEBUG] Success to update filter')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update filter')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addFilter(filterData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/delivery/filter/', filterData)
        .then(response => {
          log.debug('[DEBUG] Success to add filter')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add filter')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteFilter(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/delivery/filter/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete filter')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete filter')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
