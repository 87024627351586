import log from 'loglevel'
import axios from 'axios'

import Assistance from './assistance'
import Interest from './interest'
import File from './file'
import Commitment from './commitment'
import OrderItem from './item'
import Delivery from './delivery'
import Invoicing from './invoicing'
import Filter from './filter'
import History from './history'
import OrderProduct from './product'
import Audit from './audit'

import Cache from '../utils/cache'

/* eslint class-methods-use-this: ["off"] */

export default class Order {
  constructor() {
    log.info('[INFO] Initialize Order Module')
    this.cache = new Cache()

    this.assistance = new Assistance()
    this.audit = new Audit()
    this.interest = new Interest()
    this.file = new File()
    this.commitment = new Commitment()
    this.item = new OrderItem()
    this.product = new OrderProduct()
    this.delivery = new Delivery()
    this.invoicing = new Invoicing()
    this.filter = new Filter()
    this.history = new History()
  }

  fetchOrders(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch orders')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch orders')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchOrderList(params) {
    let parameters = {}

    if (params) {
      parameters = {
        params,
      }
    }

    const result = this.cache.bind(parameters, 10, () => {
      const promise = new Promise((resolve, reject) => {
        axios
          .get('v1/order/list/', parameters)
          .then(response => {
            log.debug('[DEBUG] Success to fetch orders')
            resolve(response)
          })
          .catch(error => {
            log.error('[ERROR] Fail to fetch orders')
            log.error(error)
            reject(error.response)
          })
      })

      return promise
    })

    return result
  }

  fetchOrder(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/order/${id}/`)
        .then(response => {
          log.debug('[DEBUG] Success to fetch order')
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to fetch order')
          log.error(error)
          reject(error.response)
        })
    })
  }

  updateOrder(id, orderData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`v1/order/${id}/`, orderData)
        .then(response => {
          log.debug('[DEBUG] Success to update order')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to update order')
          log.error(error)
          reject(error.response)
        })
    })
  }

  addOrder(orderData) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/order/', orderData)
        .then(response => {
          log.debug('[DEBUG] Success to add order')
          this.cache.reset()
          resolve(response)
        })
        .catch(error => {
          log.error('[ERROR] Fail to add order')
          log.error(error)
          reject(error.response)
        })
    })
  }

  deleteOrder(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`v1/order/${id}/`)
        .then(() => {
          log.debug('[DEBUG] Success to delete order')
          this.cache.reset()
          resolve()
        })
        .catch(error => {
          log.error('[ERROR] Fail to delete order')
          log.error(error)
          reject(error.response)
        })
    })
  }
}
